.km-surrogateControl {
    padding-bottom: 32px;
    .km-form {
        table {
            table-layout: fixed;
        }

        fieldset {
            padding: 24px 0;
            border-top: 1px solid #f7f7f7;
        }

        input[type=submit] {
            margin-top: 0;
        }
    }
}