.pagePart {
    margin: 1rem 0;

    &--html {

    }

    &--image {

    }

    &--video {

    }

    &--gallery {

    }

    &--file {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.imagePagePart {
    max-width: 100%;
    display: block;

    &--start {

    }

    &--center {
        margin: 0 auto;
    }
}

.imagePagePart__image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    display: block;
    height: auto;
    width: auto;
}

.videoPagePart {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
}

.videoPagePart__frame {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.filePagePart__link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    svg {
        margin-right: 8px;

        path {
            fill: currentColor;
        }
    }
}

.spacerPagePart {
    &.--small {
        height: 1em;
    }
    &.--medium {
        height: 2em;
    }
    &.--large {
        height: 4em;
    }
    &.--extraLarge {
        height: 6em;
    }
}

html {
    &.--mobileApp {
        .pagePart {
            display: none;
            &.--deviceType-mobileApp {
                display: block;
            }
            &.--deviceType-all {
                display: block;
            }
        }
    }
    &:not(&.--mobileApp) {
        .pagePart {
            &.--deviceType-mobileApp {
                display: none;
            }
        }
    }
}