.recaptcha {
    margin: 12px 0;
}

.recaptcha__info {
    height: 24px;
}

.recaptcha__link {
    display: inline-flex;
    align-items: center;
    color: inherit;
}

.recaptcha__img {
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-right: 8px;
}

.recaptcha__text {
    opacity: .5;
    font-size: .9em;
}