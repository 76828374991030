@modal-zindex: 100;
@modal-bg: #E2E8EE;
@modal-border-color: darken(#E2E8EE, 20%);
@border-radius: 3px;

.clearfix() {
    &::after {
        clear: both;
        display: table;
        content: " ";
    }
}

.backdrop(@zindex) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: @zindex;
}

.arrow-top(@size, @color, @left: 50%) {
    &:before {
        display: block;
        content: " ";
        top: -@size;
        left: @left;
        transform: rotate(-135deg);
        height: 2 * @size;
        width: 2 * @size;
        position: absolute;
        margin-left: -@size;
        border-radius: 0 0 round(@size * 0.6) 0;
        z-index: -1;
        background-image: linear-gradient(-45deg, @color 50%, transparent 50%);
    }
}

.articleVehiclesList() {
    .articleVehiclesList {
        .articleVehiclesList__header {
            border-bottom: 5px solid;
            font-size: 22px;
            padding-top: 30px;
            padding-bottom: 5px;
        }
    }
}

.articleVehiclesList();

.manufacturerItemChunk() {
    .manufacturerItemChunk {
        margin-left: -5px;
        margin-right: -5px;

        .clearfix();
    }
}

.manufacturerItemChunk();

.manufacturerItem(@border-radius) {
    .manufacturerItem {
        float: left;
        width: 25%;

        .manufacturerItem__link {
            display: block;
            text-decoration: none !important;
            padding: 3px 10px;
            margin: 0 5px 3px 5px;
            border-radius: @border-radius;

            .manufacturerItem__image {
                display: none;
                max-width: 25%;
                margin-right: 8px;
                max-height: 32px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &:hover {
                text-decoration: none;
            }
        }

        &.manufacturerItem--current {
            font-weight: bold;
        }
    }
}

.manufacturerItem(@border-radius);

.manufacturerItem__modelItems {
    margin-bottom: 15px;
    margin-top: 15px;
}

.modelItemGroup() {
    .modelItemGroup {
        padding: 2px 0;
        border-top: 1px solid;

        .modelItemGroup__shortName {
            padding-left: 10px;
            width: 16.66%;
            float: left;
            font-weight: bold;
        }

        .modelItemGroup__modelItemChunks {
            width: 100% - (100% / 6);
            float: left;
        }

        .clearfix();
    }
}

.modelItemGroup();

.modelItemChunk() {
    .modelItemChunk {
        position: relative;

        .clearfix();
    }
}

.modelItemChunk();

.modelItem(@border-radius) {
    .modelItem {
        float: left;
        width: 50%;

        .modelItem__link {
            display: block;
            margin: 0 5px 3px 5px;
            padding: 3px 10px;
            border-radius: @border-radius;

            .modelItem__title {
                float: left;
            }

            .modelItem__yearOfConstrRange {
                float: right;
            }

            .clearfix();
        }

        &.modelItem--current {
            font-weight: bold;
        }
    }
}

.modelItem(@border-radius);

.vehicleItemsModal(@bg, @zindex, @border-color, @border-radius) {
    .vehicleItemsModal {
        position: absolute;
        top: 100%;
        margin-top: 10px;
        width: 100%;
        z-index: @zindex;
        background-color: @bg;
        border-radius: @border-radius;
        border: 1px solid @border-color;
        box-shadow: 0 0 10px fade(black, 20%);

        &.vehicleItemsModal--left {
            .arrow-top(10px, @border-color, 10%);
        }

        &.vehicleItemsModal--right {
            .arrow-top(10px, @border-color, 60%);
        }

        .vehicleItemsModal__head {
            padding: 10px;
            display: flex;

            .vehicleItemsModal__header {
                font-size: 16px;
                font-weight: 600;
                margin: 10px 0;
            }

            .vehicleItemsModal__close {
                border: 0;
                background: 0;
                padding: 0;
                margin-left: auto;
                font-size: 40px;
                line-height: 1;
                font-weight: 400;
                font-family: Arial, sans-serif;
                opacity: 0.2;

                &:hover {
                    opacity: 0.5;
                }
            }

            .clearfix();
        }
    }

    .vehicleItemsModal__backdrop {
        .backdrop(@zindex - 1);
    }
}

.vehicleItemsModal(@modal-bg, @modal-zindex, @modal-border-color, @border-radius);

.ajax-loader() {
    .ajax-loader--loadVehicleItems, .ajax-loader--loadModelItems, .ajax-loader--vehicleProduct {
        .ajax-loader__pendingText {
            margin-top: 10px;
            display: block;
            text-align: center;
            line-height: 1;
            font-size: 17px;
            font-weight: normal;
        }

        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.ajax-loader();

.vehicleItem(@border-radius) {
    .vehicleItem {
        .vehicleItem__link {
            border-top: 1px solid @modal-border-color;
            display: block;
            padding: 5px 10px;

            .vehicleItem__title {
                width: 40%;
                float: left;
                display: block;
            }

            .vehicleItem__powerRange {
                width: 30%;
                float: left;
                display: block;
            }

            .vehicleItem__yearOfConstrRange {
                width: 30%;
                float: left;
                display: block;
                text-align: right;
            }

            .clearfix();
        }

        &.vehicleItem--current {
            font-weight: bold;
        }
    }
}

.vehicleItem(@border-radius);

