.pull-right {
    float: right;
}

.cleaner {
    clear: both;
}

.text-muted {
    color: #999;
}

#heureka-garance-box {
    margin: 20px;
    text-align: center;
}

[data-fancybox] {
    cursor: zoom-in;
}


.kvikymart-js-flashMessage {
    display: none;
}

.kvikymart-cleaner {
    clear: both;
}

.fancybox-container {
    z-index: var(--ksfdef-z-index-modal-dialog);
}

.fancybox-bg {
    background-color: white;
}

.fancybox-is-open .fancybox-bg {
    opacity: 1;
}

@keyframes km-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
    }
}


.km-spinner {
    margin: 0 auto;
    width: 32px;
    height: 32px;
    text-align: center;

    @keyframes km-spin {
        to {
            transform: rotate(360deg);
        }
    }

    > div {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 4px solid rgba(0, 0, 0, .15);
        border-top-color: rgba(0, 0, 0, .65);
        animation: .6s km-spin infinite linear;
    }

    &.--light > div {
        border: 4px solid rgba(255, 255, 255, .35);
        border-top-color: rgba(255, 255, 255, 1);
    }
}

.kvikymart-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    user-select: none;
    color: var(--sf-btn-default-text-color, #333333);
    background-color: var(--sf-btn-default-bg-color, #f0f0f0);
    text-decoration: none;
    height: var(--sf-btn-height);

    @media (hover) {
        &:hover {
            color: var(--sf-btn-default-hover-text-color, #333333);
            background-color: var(--sf-btn-default-hover-bg-color, #f0f0f0);
        }
    }
}

.kvikymart-btn-primary {
    color: var(--sf-btn-primary-text-color, #ffffff);
    background-color: var(--sf-btn-primary-bg-color, #5cb85c);
    font-weight: bold;

    &:hover {
        color: var(--sf-btn-primary-hover-text-color, #ffffff) !important;
        background-color: var(--sf-btn-primary-hover-bg-color, #5cb85c) !important;
        box-shadow: var(--sf-btn-primary-hover-box-shadow) !important;
    }
}


.kvikymart-form-control {
    height: 31px;
    padding: 6px 8px;
    font-size: 14px;
    line-height: 1;
    color: #555555;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
}


.kvikymart-error-tooltip {
    position: absolute;
    z-index: 1030;
    display: block;
    font-size: 18px;
    text-shadow: none;
    padding: 8px 20px;
    max-width: 400px;
    line-height: 1.5;
    font-weight: bold;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    margin: 10px 0 0 -15px;
    border-radius: 2px;
    box-shadow: 0 3px 13px rgba(0, 0, 0, 0.7);
    transition: opacity 0.2s;

    &:after {
        bottom: 100%;
        content: " ";
        border-style: solid;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-bottom-color: black;
        border-bottom-color: rgba(0, 0, 0, 0.85);
        border-width: 10px;
        left: 10px;
    }
}


.kvikymart-modal-header {
    margin-bottom: 24px;
}

.kvikymart-modal-footer {
    margin-top: 24px;
}

.kvikymart-addtocart-modal-body {
    position: relative;
}

.kvikymart-addtocart-modal-body img {
    position: absolute;
    left: 0;
    top: 0;
    max-height: 100px;
    max-width: 100px;
}

.kvikymart-addtocart-modal-content {
    padding-left: 115px;
    min-height: 100px;
}

.kvikymart-addtocart-modal-footer {
    label {
        display: inline-block;
        line-height: 33px;
        width: 115px;
        vertical-align: top;
        margin: 0;
    }

    input[type=number] {
        width: 70px;
    }

    p {
        margin: 0;
    }
}

.kvikymart-phone-number-control {
    display: flex;

    .kvikymart-phone-number-control-country-prefix {
        flex: 7em 0 0;
    }

    .kvikymart-phone-number-control-national-number {
        flex: auto 1 1;
        margin-left: .25em;
    }
}

#kvikymart-scdpa {
    #kvikymart-scdpa-delivery {
        float: left;
        width: 45%;
    }

    #kvikymart-scdpa-payment {
        float: right;
        width: 45%;
    }

    .kvikymart-scdpa-delivery-item,
    .kvikymart-scdpa-payment-item {
        label {
            cursor: pointer !important;

            .kvikymart-scdpa-item-image {
                img {
                    max-width: 32px;
                    max-height: 32px;
                }
            }
        }

        &.disabled, &.locked {
            opacity: 0.4;

            label {
                cursor: default !important;
            }
        }

        &.locked {
            pointer-events: none;
        }

        .kvikymart-scdpa-description {
            margin-block-start: 12px;

            img {
                display: block;
                max-width: 100%;
            }
        }
    }

    #kvikymart-scdpa-ajax-loader {
        height: 80px;
        visibility: hidden;
        padding: 10px 0;

        &.visible {
            visibility: visible;
        }
    }
}

.kvikymart-scdpa-country-flag {
    display: none;
}

#frm-surrogate-selectShoppingUserForm {
    padding-bottom: 180px;
}

._dialog_overlay {
    z-index: var(--ksfdef-z-index-modal-backdrop) !important; // Override z-index from dialog overlay polyfill
}