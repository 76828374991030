@mixin svgicon-size($size) {
    width: $size;
    height: $size;
}

.svgicon {
    display: inline-block;
    vertical-align: top;
    svg {
        vertical-align: top;
        fill: currentColor;
        path {
            fill: currentColor;
        }
    }
}
