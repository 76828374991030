@import "../../../AdminModule/browser/styles/variables";
@import "../../browser/SvgIcons";

$color-bg-body: rgb(244, 244, 244);
$hoverColor: $color-bg-body;
$contentColor: #eeeeee;
$openedColor: #d0d0d0;

$screen-sm: 768px;
$screen-xs-max: ($screen-sm - 1);

$borderColor: darken($color-bg-body, 10%);
$legendColor: darken($color-bg-body, 25%);

.UserOrderHistory {
    margin-top: 48px;
    @media (max-width: $screen-xs-max) {
        margin-top: 24px;
    }

    .paginatorControl {
        margin: 0;
    }

    .paginatorControl__pages {
        display: flex;
        justify-content: flex-start;
        padding: 0;
    }

    .paginatorControl__page {
        display: block;
        padding-right: 8px;
        &--first {

        }

        .btn--active {
            color: #222;
            background-color: $color-bg-body;
        }

        &--last, &--next {
            margin-left: auto;
        }
    }
}

.UserOrderHistory__loadMoreButton {
    margin: 8px;
    appearance: none;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
    background-color: transparent;
    line-height: 24px;
    padding: 8px 4px;
    color: rgba(0, 0, 0, .5);

    &:hover, &:active {
        color: rgba(0, 0, 0, .5);
        background-color: rgba(0, 0, 0, .1);
    }

    &:active {
        background-color: rgba(0, 0, 0, .15);
    }

    border-radius: var(--sf-border-radius);
    @media (max-width: $screen-xs-max) {
        margin-left: 0;
    }
}

.UserOrderHistory__noOrdersLabel {
    display: block;
    padding: 0 24px 16px 12px;

    @media (max-width: $screen-xs-max) {
        padding: 0 8px 24px;
        font-size: 1.2rem;
    }
}

.UserOrderHistory__loadMoreButtonIcon {
    margin-right: 8px;
    color: rgba(0, 0, 0, .5);
    @include svgicon-size(24px);
}

.userOrder {
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid $borderColor;
    width: 100%;
    padding: 8px 24px 8px 12px;
    align-items: center;
    flex-wrap: wrap;
    min-height: 56px;
    @media (max-width: $screen-xs-max) {
        position: relative;
        padding: 8px;
    }

    &:hover {
        background-color: $hoverColor;
    }

    &--opened {
        background-color: $openedColor;
    }
}

.userOrder__part {
    flex-basis: 18%;
    line-height: 1.5;

    @media (max-width: $screen-xs-max) {
        line-height: 1.2;
        flex-basis: 22%;
        text-align: left;
    }

    &--openIcon {
        flex-basis: 8%;
        @media (max-width: $screen-xs-max) {
            flex-basis: 10%;
        }
    }

    &--alignRight {
        text-align: right;
    }

    &--mobileHidden {
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
}

.userOrder__part__secondLine {
    padding-top: 4px;
    &--mobileOnly {
        display: none;
        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }
}

.userOrderOpenIcon {
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    opacity: .35;

    @media (max-width: $screen-xs-max) {
        width: 12px;
        height: 12px;
        line-height: 12px;
    }

    transition: transform .1s linear;

    &--opened {
        transform: rotate(45deg);
    }
}

.userOrderOpenIcon__line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: black;
    left: 0;
    top: 50%;

    &--vertical {
        transform: rotate(90deg);
    }
}

.userOrderDetail {
    background: $contentColor;
    display: none;
    padding: 24px;
    @media (max-width: $screen-xs-max) {
        padding: 0 16px 16px;
    }

    &--visible {
        display: block;
        margin-bottom: 48px;
    }
}

.userOrderDetail__heading {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 16px;
    @media (max-width: $screen-xs-max) {
        padding-top: 16px;
        margin-bottom: 12px;
        font-size: 1.2rem;
    }
}

.userOrderDetailLine {
    width: 100%;
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $borderColor;
}

.userOrderDetailLine__part {
    flex: 0 0 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: wrap;

    @media (max-width: $screen-xs-max) {
        font-size: 1.2rem;
        flex-basis: 100%;
    }

    &--alignRight {
        text-align: right;
    }

    &--grow {
        flex-grow: 1;
    }

    &--fullWidth {
        flex-basis: 100%;
    }
}

.userOrderDetailTotalPrice {
    margin-top: 16px;
    text-align: right;
}

.userOrderDetailTotalPrice__priceAmount {
    display: block;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.9rem;
    @media (max-width: $screen-xs-max) {
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
}

.userOrderDetailTotalPrice__priceAmountWithoutVat {
    font-weight: normal;
    @media (max-width: $screen-xs-max) {
        font-size: 1.3rem;
    }
}

.userOrderDetail__comment {

}

.userOrderDetail__commentText {
    padding: 0 0 8px;
    @media (max-width: $screen-xs-max) {

        font-size: 1.2rem;
        line-height: 2rem;
    }
}

.userOrderDetail__contacts {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    @media (max-width: $screen-xs-max) {
        margin-top: 0;
        flex-wrap: wrap;
        flex-basis: 100%;
    }
}

.userOrderBillingDeliveryContact {
    flex: 0 0 48%;
    @media (max-width: $screen-xs-max) {
        flex-basis: 100%;
        font-size: 1.2rem;
        line-height: 2rem;
    }
}

.userOrderBillingDeliveryContact__heading {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $borderColor;
    @media (max-width: $screen-xs-max) {
        margin-top: 32px;
        margin-bottom: 8px;
        font-size: 1.25rem;
    }
}

.paymentStatus {
    font-weight: bold !important;

    &--paid {
        color: limegreen !important;
        cursor: default !important;

        &:hover {
            color: limegreen !important;
        }
    }

    &--pay {
        color: orange;
    }
}