:root {
    --sf-cookie-consent-button-grant-all-bg-color: limegreen;
    --sf-cookie-consent-button-grant-all-text-color: white;
}

#cookieConsent__sectionStorageConsent[hidden],
#cookieConsent__sectionSelection[hidden],
#cookieConsent__buttonSelection[hidden],
#cookieConsent__buttonGrantSelection[hidden],
#cookieConsent__buttonGrantNone[hidden] {
    display: none;
}

.cookieConsent__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-inline-end: 28px;

    h1, h2, h3 {
        font-weight: bold;
        font-size: 1.5rem;
        margin: 0;
    }
}

.cookieConsent__languageForm {

}

.cookieConsent__languageSelect {
    height: 24px;
    border-radius: var(--sf-border-radius);
    appearance: none;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: .8rem;
    padding: 0 8px;
    padding-inline-end: 28px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    outline: none;
}

.cookieConsent__sectionButtons {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
}

.cookieConsent__sectionButtons__cell {
    display: flex;
    flex-grow: 1;
    min-width: 50%;
}

.cookieConsent__button {
    height: 48px;
    padding: 0 12px;
    border-radius: var(--sf-border-radius);
    font-size: 1rem;
    flex-grow: 1;
    background-color: white;
    border: none;
    cursor: pointer !important;
    margin-inline-end: 16px;

    &.--default {
        color: black !important;
        text-decoration: underline !important;
        text-underline-offset: 4px;
        opacity: .7;

        @media (hover) {
            &:hover {
                opacity: 1;
            }
        }
    }

    &.--grantSelection {
        color: black !important;
        background-color: rgba(0, 0, 0, .1);
        font-weight: bold;

        @media (hover) {
            &:hover {
                box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
            }
        }
    }

    &.--grantAll {
        margin-inline-end: 0;
        flex-grow: 1;
        min-width: 50%;
        font-size: 1rem;
        font-weight: bold;
        color: var(--sf-cookie-consent-button-grant-all-text-color) !important;
        background-color: var(--sf-cookie-consent-button-grant-all-bg-color);

        @media (hover) {
            &:hover {
                box-shadow: 0 2px 4px var(--sf-cookie-consent-button-grant-all-bg-color);
            }
        }
    }
}

.cookieConsent__sectionArticleLink {
    margin-top: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 12px;

    a {
        font-size: .8rem;
        color: black !important;
        text-decoration: underline !important;
        text-underline-offset: 2px;
        opacity: .6;

        @media (hover) {
            &:hover {
                opacity: 1;
            }
        }
    }
}

#cookieConsent__sectionSelection {
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.cookieConsent__sectionConsent {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 16px 0;
}

.cookieConsent__sectionConsent__textCell {
    h1, h2, h3, h4, h5, h6 {
        font-size: 1.1rem !important;
        font-weight: bold;
        margin: 0 0 .5rem;
    }

    p {
        opacity: .65;
    }
}

.cookieConsent__sectionConsent__switcherCell {
    margin-top: 16px;
}

.cookieConsent__sectionConsent__switcherLabel {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    user-select: none;

    input {
        margin-inline-end: 12px !important;
    }
}

.cookieConsent__sectionConsent__switcherLabel__text {
    display: none;

    &.--visible {
        display: block;
    }
}