// TODO: Remove when all eshops are compatible!
.product-supplies-overview,
.productSuppliesOverview,
.product-detail-supplies,
.productDetailSupplies,
.product-detail-supplies-overview,
.productDetailSuppliesOverview {
    > * {
        display: none;
    }
}

#SuppliesOverviewIframe {
    display: block !important;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 300px;
}
