.backdrop {
    display: none !important;
}

#modal__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--ksfdef-z-index-modal-backdrop);
    background-color: var(--sf-body-backdrop-bg-color, rgba(0, 0, 0, .75));
    backdrop-filter: blur(var(--sf-body-backdrop-blur, 0));
}


#modal__loadingPane {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, .9);
    backdrop-filter: blur(var(--sf-body-backdrop-blur, 0));
    display: flex;
    align-items: center;
    justify-content: center;
}


#modal__dialog {
    font-size: var(--sf-font-size-root, 14px);
    position: fixed;
    max-height: 100dvh;
    width: 750px;
    margin: 0 auto;
    overflow-y: auto;
    z-index: var(--ksfdef-z-index-modal-dialog) !important;
    background-color: white;
    border-radius: var(--sf-border-radius);
    border: none;
    padding: 20px;
    box-shadow: var(--sf-modal-box-shadow, none);
    opacity: 0;
    transition-property: opacity; //, transform;
    transition-timing-function: cubic-bezier(.86, 0, .07, 1);
    transition-duration: .25s;
    transform-origin: center top;
    transform: translateY(-50%) scaleY(1);
    top: 50%;

    &.--open {
        opacity: 1;
        transform: translateY(-50%) scale(1);
    }

    &.--cookieConsent.--no-ajax {
        top: 20px;
        bottom: auto;
        transform: scaleY(1);
        @media (max-width: 740px) {
            top: 0;
        }

        &.--open {
            transform: scale(1);
        }
    }

    &.--loader {
        width: 100px !important;
        height: 100px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--sf-modal-loader-bg-color, black);
    }

    @media (max-width: 790px) {
        &:not(.--loader) {
            width: 100vw;
            max-width: 100vw;
            margin-inline: 0;
            max-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 100px); // -100px because of mobile browser bars
        }
    }

    &.--size-full {
        width: 100vw;
        //height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 100px);
        height: 100dvh;
        @media (min-width: 1600px) {
            width: 1600px;
        }
    }

    &.--position-relative {
        position: relative;
        opacity: 1;
    }
}

#modal__close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    border: none;
    opacity: .3;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    cursor: pointer;
    z-index: 1000;

    &:before, &:after {
        position: absolute;
        top: 9px;
        left: 0;
        content: ' ';
        height: 2px;
        width: 20px;
        background-color: black;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    @media (hover) {
        &:hover {
            opacity: 1;
        }
    }
}

.modal__dialog+.backdrop {
    z-index: var(--ksfdef-z-index-modal-backdrop) !important;
}