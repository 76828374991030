@import "../../AdminModule/browser/styles/variables.scss";

.tabRouter {

}

$tabHeight: 40px;
.tabRouter__tabLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    height: $tabHeight;
    border-top: none;
    border-bottom: 1px solid var(--sf-color-darken-a10);
}

.tabLink {
    text-align: center;
    background-color: transparent;
    border-bottom-color: transparent;
    flex-basis: 50%;
    line-height: $tabHeight;
    font-size: 15px;
    font-weight: bold;

    &:active, &:hover, &:focus, &:visited {
        text-decoration: none;
    }

    &:hover {
        color: #222;
    }
}

.tabLink--selected {
    color: #222;
    background-color: rgb(244, 244, 244);
}

.tabContent {
    display: none;

    &--active {
        display: block;
    }
}