:root {
    --sf-switcher-input-bg-color: red;
    --sf-switcher-input-checked-bg-color: limegreen;
}

input[type=checkbox].switcherInput {
    appearance: none;
    height: 24px;
    width: 40px;
    border-radius: 12px;
    display: inline-block;
    cursor: pointer;
    border: none;
    background-clip: border-box;
    box-shadow: none !important;
    background-color: currentColor !important;
    vertical-align: middle;
    position: relative;
    background-image: none !important;
    overflow: hidden;
    color: var(--sf-switcher-input-bg-color);
    transition-property: color;
    transition-duration: .2s;
    margin: 0;

    &:before {
        position: absolute;
        left: 2px;
        top: 2px;
        height: 20px;
        width: 20px;
        display: block;
        content: '×';
        font-size: 16px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        background-color: white;
        color: white;
        box-shadow: 0 0 4px rgba(0, 0, 0, .1);
        transition-property: transform;
        transition-duration: .2s;
    }

    &:hover {
    }

    &:checked {
        color: var(--sf-switcher-input-checked-bg-color);

        &:before {
            transform: translateX(16px);
        }
    }

    &:disabled {
        pointer-events: none;
        filter: none;
    }

    // Use disabled attribute in addition of :disabled pseudo-class
    // See: https://github.com/twbs/bootstrap/issues/28247
    &[disabled] {
        cursor: not-allowed !important;
        opacity: 1 !important;

        &:before {
            color: currentColor;
        }
    }
}