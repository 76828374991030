.km-assembly-groups-tree {
    .loader.loaded {
        display: none;
    }
}

.km-treeNavigation__nameSuffix {
    display: none;
}

.km-treeNavigation__iconImage {
    display: none;
}
